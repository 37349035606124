<template>
  <component
    :is="component"
    :disabled="disabled"
    :class="classes"
    :href="href"
    :to="to"
    v-bind="$attrs"
  >
    <slot />
  </component>
</template>

<script>
const COLORS = [
  'blue',
  'red',
  'black'
]

const VARIANTS = [
  'filled',
  'outline',
  'filledBrighter'
]

const SIZES = [
  'large',
  'medium'
]

export default {
  props: {
    color: {
      type: String,
      default: COLORS[0],
      validator: c => COLORS.includes(c)
    },
    variant: {
      type: String,
      default: VARIANTS[0],
      validator: v => VARIANTS.includes(v)
    },
    size: {
      type: String,
      default: SIZES[0],
      validator: s => SIZES.includes(s)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
      default: undefined
    },
    href: {
      type: String,
      default: undefined
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    component () {
      if (this.to) { return 'NuxtLink' } else if (this.href) { return 'a' }
      return 'button'
    },
    classes () {
      return [
        this.$style.PWButton,
        this.$style[this.color],
        this.$style[this.variant],
        this.$style[this.size],
        {
          [this.$style.fullWidth]: this.fullWidth
        }
      ]
    }
  }
}
</script>

<style module lang="scss">
@use 'assets/scss/mixins';

.PWButton {
  text-decoration: none;
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap);
  padding: var(--py) var(--px);
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;

  @include mixins.button;
}

.large {
  --px: 22px;
  --py: 8px;
  --gap: 8px;
}

.medium {
  --px: 16px;
  --py: 5px;
  --gap: 8px;
}

.filled {
  transition: background-color 0.3s, color 0.3s;
}

.blue.filled {
  --c: var(--white);
  --bg-default: var(--main-blue);
  --bg-hover: var(--main-blue-dark);
  --bg-active: var(--main-blue-light);
}

.black.filled {
  --c: var(--white);
  --bg-default: var(--grey-900);
  --bg-hover: var(--grey-800);
  --bg-active: var(--grey-700);
}

.filled:not([disabled]) {
  background-color: var(--bg-default);
  color: var(--c);

  &:hover {
    background-color: var(--bg-hover);
  }

  &:active,
  &:focus,
  &:focus-visible {
    background-color: var(--bg-active);
  }
}

.filled:disabled {
  background-color: var(--grey-300);
  color: var(--disabled);
  cursor: not-allowed;
}

.outline {
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 0 0 1px currentColor;
}

.blue.outline {
  --c: var(--main-blue);
  --bg-default: var(--white);
  --bg-hover: var(--blue-shade-4);
  --bg-active: var(--blue-shade-8);
}

.red.outline {
  --c: var(--main-red);
  --bg-default: var(--white);
  --bg-hover: var(--red-shade-4);
  --bg-active: var(--red-shade-8);
}

.outline:not([disabled]) {
  background-color: var(--bg-default);
  color: var(--c);

  &:hover {
    background-color: var(--bg-hover);
  }

  &:active,
  &:focus,
  &:focus-visible {
    background-color: var(--bg-active);
  }
}

.outline:disabled {
  background-color: var(--white);
  color: var(--disabled);
  cursor: not-allowed;
}

.fullWidth {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.filledBrighter {
  @extend .filled;
}

.filledBrighter:disabled {
  background-color: var(--grey-200);
}
</style>
